import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { PhoneFieldService } from '../phone-field.service';
import { savePhoneFields, savePhoneFieldComplete } from './phone-field.actions';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneFieldMessageConverter } from '../../../utils/message-converters/phone-field/phone-field-message-converter';
import { MessageKey } from '../../../models/message/message-key';
import { HttpStatusCode } from 'src/app/dtos/http-status-code';
import { addRainBookingRemarks, addRainBookingRemarksComplete } from 'src/app/features/initial-booking/state/initial-booking.actions';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';

@Injectable()
export class PhoneFieldEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly phoneFieldService: PhoneFieldService,
    private readonly phoneFieldMessageConverter: PhoneFieldMessageConverter
  ) {}

  savePhoneFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePhoneFields),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        return this.phoneFieldService.savePhoneFields(action.request, confirmationCode, action.isPAWDRemark).pipe(
          map((result) => {
            if (action.isPAWDRemark) {
              // If the request to save the phone field is for a PAWD remark fails, we are not going to add rain booking remarks
              if (result.statusCode === HttpStatusCode.OK) {
                this.store.dispatch(addRainBookingRemarks());
              } else {
                this.store.dispatch(addRainBookingRemarksComplete(AddDeleteRemarkStatus.FULL_FAILURE));
              }
            } else {
              result = this.phoneFieldMessageConverter.convertSavePhoneFieldMessage(result);
              this.store.dispatch(addRainBookingRemarksComplete(AddDeleteRemarkStatus.FULL_FAILURE));
            }
            return savePhoneFieldComplete(result);
          }),
          catchError((err: HttpErrorResponse) => {
            const mappedErrorKeys = [MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC];
            if (action.isPAWDRemark) {
              this.store.dispatch(addRainBookingRemarksComplete(AddDeleteRemarkStatus.FULL_FAILURE));
            }
            return of(savePhoneFieldComplete({ statusCode: err.status, exceptionContent: err.message, mappedErrorKeys }));
          })
        );
      })
    )
  );
}
