import { Injectable } from '@angular/core';
import { MessageKey } from '../../../models/message/message-key';
import { AddBulkReservationInformationResponse } from '../../../dtos/response/reservation-infomration-response/bulk-reservtion-infomration-response';

@Injectable({
  providedIn: 'root',
})
export class ContanctInformationFieldsMessageConverter {
  /**
   * Map message keys to the phone field response based on error conditions
   * @param AddBulkReservationInformationResponse The response from the phone field save
   * @returns An updated AddBulkReservationInformationResponse with mapped message keys
   */

  convertSaveContactInformationFieldsMessage(
    addBulkReservationInformationResponse: AddBulkReservationInformationResponse
  ): AddBulkReservationInformationResponse {
    let mappedMessageKeys: MessageKey[] = [];

    if (!addBulkReservationInformationResponse) {
      mappedMessageKeys.push(MessageKey.UNKNOWN_WITH_LINK);
    } else {
      // Timeout
      if (addBulkReservationInformationResponse.exceptionContent?.toLowerCase().includes('timeout')) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_TIMEOUT);
      }
      // Password Decryption Error
      if (addBulkReservationInformationResponse.exceptionContent?.toLowerCase().includes('password decryption error')) {
        mappedMessageKeys.push(MessageKey.PASSWORD_DECRYPTION_ERROR);
      }
      // Partial failure
      // In the case of a partial failure we will map this and the message keys specific to the errors
      if (
        addBulkReservationInformationResponse?.failedList &&
        addBulkReservationInformationResponse?.failedList.length > 0 &&
        addBulkReservationInformationResponse?.successList &&
        addBulkReservationInformationResponse.successList.length > 0
      ) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_PARTIAL_FAILURE);
      }
      // Mapped errors - specific to the error
      if (addBulkReservationInformationResponse?.failedList && addBulkReservationInformationResponse?.failedList?.length > 0) {
        addBulkReservationInformationResponse.failedList.forEach((failure) => {
          mappedMessageKeys = this.convertSaveContactInformationFieldsErrorMessages(failure.failureMessage, mappedMessageKeys);
        });
      }
      // If we've identified an error and made it this far without mapping a key, we'll map the unknown error key.
      if (
        addBulkReservationInformationResponse.statusCode !== 200 &&
        addBulkReservationInformationResponse.statusCode !== 207 &&
        mappedMessageKeys?.length === 0
      ) {
        mappedMessageKeys?.push(MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC);
      }
    }

    addBulkReservationInformationResponse = {
      ...addBulkReservationInformationResponse,
      mappedErrorKeys: mappedMessageKeys,
    };

    return addBulkReservationInformationResponse;
  }

  convertSaveContactInformationFieldsErrorMessages(failureMessage: string, mappedMessageKeys: MessageKey[]): MessageKey[] {
    if (failureMessage) {
      const failerMessageLower = failureMessage?.toLowerCase();

      if (failerMessageLower.includes('simultaneous changes')) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_SIMULTANEOUS_CHANGES);
      }
    }
    return mappedMessageKeys;
  }
}
