import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth-service/auth.service';
import { LoggerService } from './services/logger-service/logger.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.authService.onInit();
    this.renderAppDynamicsScripts();
  }

  ngOnDestroy(): void {
    // Flush any pending logs to ensure that any remaining queued messages are sent to Sumo Logic.
    this.loggerService.flushLogs();
  }

  private renderAppDynamicsScripts() {
    const appKey = environment?.appDynamicsKey;

    if (appKey) {
      const js = `
        window["adrum-start-time"] = new Date().getTime();
        (function(config){
            config.appKey = "${appKey}";
            config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
            config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
            config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
            config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
            config.useHTTPSAlways = true;
            config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
            config.maxUrlLength = 512;
            config.isZonePromise = true;
            config.angular = true;
        })(window["adrum-config"] || (window["adrum-config"] = {}));
      `;
      const scriptJs = document.createElement('script');
      scriptJs.type = 'text/javascript';
      scriptJs.innerHTML = js;
      const body = document.getElementsByTagName('body')[0];

      const scriptAppDJsLibCdn = document.createElement('script');
      scriptAppDJsLibCdn.src = '//cdn.appdynamics.com/adrum/adrum-23.10.1.4359.js';

      body.appendChild(scriptJs);
      body.appendChild(scriptAppDJsLibCdn);
    }
  }
}
