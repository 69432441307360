import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, defer, map, of, throwError, timeout } from 'rxjs';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { TimeoutLimit } from '../../models/timeout-limit';
import { timeoutError } from '../../models/timeout-error';
import { HttpStatusCode } from '../../dtos/http-status-code';
import { AddBulkReservationInformationRequest } from 'src/app/dtos/request/reservation-information-request/add-bulk-reservation-information-request';
import { AddBulkReservationInformationResponse } from '../../dtos/response/reservation-infomration-response/bulk-reservtion-infomration-response';

export interface ContactInformationFieldsServiceAPI {
  saveContactInformationFields(
    request: AddBulkReservationInformationRequest,
    confirmationCode: string
  ): Observable<AddBulkReservationInformationResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ContactInformationFieldsService implements ContactInformationFieldsServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  saveContactInformationFields(
    request: AddBulkReservationInformationRequest,
    confirmationCode: string
  ): Observable<AddBulkReservationInformationResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http
      .post<AddBulkReservationInformationResponse>(
        `api/reservation/${confirmationCode}/reservation-information/bulk-add-or-update`,
        request,
        options
      )
      .pipe(
        timeout({
          each: TimeoutLimit.MEDIUM,
          with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
        }),
        map((response) => {
          const statusCode =
            response?.failedList?.length > 0 && response?.successList.length > 0 ? HttpStatusCode.MULTI_STATUS : HttpStatusCode.OK;
          const fullResponse: AddBulkReservationInformationResponse = { statusCode, ...response };
          return fullResponse;
        }),
        catchError((err) => {
          if (timeoutError.statusText === err.statusText) {
            this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
            return of({ statusCode: HttpStatusCode.GATEWAY_TIMEOUT, exceptionContent: 'Timeout', response: null });
          }
          return of({ statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR, exceptionContent: err.error, response: null });
        })
      );
  }
}
