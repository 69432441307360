import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { ContactInformationFieldsService } from '../contact-information-fields.service';
import { saveContactInformationFields, saveContactInformationFieldsComplete } from './contact-information-fields.actions';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { ContanctInformationFieldsMessageConverter } from '../../../utils/message-converters/contact-information-fields/contact-information-fields-message-converter';
import { MessageKey } from '../../../models/message/message-key';

@Injectable()
export class ContactInformationFieldsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private phoneFieldService: ContactInformationFieldsService,
    private contactInformationFieldsMessageConverter: ContanctInformationFieldsMessageConverter
  ) { }

  contactInformationFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveContactInformationFields),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        return this.phoneFieldService.saveContactInformationFields(action.request, confirmationCode).pipe(
          map((result) => {
            result = this.contactInformationFieldsMessageConverter.convertSaveContactInformationFieldsMessage(result);
            return saveContactInformationFieldsComplete(result);
          }),
          catchError((err: HttpErrorResponse) => {
            const mappedErrorKeys = [MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC];
            return of(saveContactInformationFieldsComplete({ statusCode: err.status, exceptionContent: err.message, mappedErrorKeys }));
          })
        );
      })
    )
  );
}
