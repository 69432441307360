import { Action, createReducer, on } from '@ngrx/store';
import { initialContactInformationFieldsState } from './contact-information-fields.state';
import { initializeContactInformationFieldsState, saveContactInformationFieldsComplete } from './contact-information-fields.actions';

const featureReducer = createReducer(
  initialContactInformationFieldsState,
  on(initializeContactInformationFieldsState, (state) => ({ ...state, ...initialContactInformationFieldsState })),
  on(saveContactInformationFieldsComplete, (state, { response }) => ({...state, BulkReservationInformationResponse: response})),
);

export function contactInformationFieldsReducer(state = initialContactInformationFieldsState, action: Action) {
  return featureReducer(state, action);
}
