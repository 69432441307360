import { Action, createReducer, on } from '@ngrx/store';
import { contactInformationFormsAdapter, ContactInformationFormsState, initialContactInformationFormsState } from './contact-information-forms.state';
import {
  addInitialContactInformationFormData,
  addNewContactInformationPhoneNumberForm,
  initializeContactInformationFormsState,
  removeContactInformationPhoneNumberForm,
  setContactInfoFormExpanded,
  setContactInfoSaved,
  updateContactInformationFormEmailData,
  updateContactInformationFormPhoneData,
} from './contact-information-forms.actions';
import { GUEST_PHONE_NUMBER_FORM_LABEL, PRIMARY_PHONE_NUMBER_FORM_LABEL } from 'src/app/services/string-constants';
import { PhoneFormData } from 'src/app/models/contact-information-forms/phone-form-data';
import { ContactInformationForm } from 'src/app/models/contact-information-forms/contact-information-form';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialContactInformationFormsState,
  /**
   * Reinitialize the contact information forms state
   */
  on(initializeContactInformationFormsState, (state) => ({ ...state, ...initialContactInformationFormsState })),
  /**
   * Sets the form expanded state for the guest contact form
   */
  on(setContactInfoFormExpanded, (state, { formExpanded }) => {
    return {
      ...state,
      formExpanded,
    };
  }),
  /**
   * Sets the contactInfoSaved property
   */
  on(setContactInfoSaved, (state, { formSaved, lastSavedData }) => {
    return {
      ...state,
      formSaved,
      lastSavedContactInformationForms: contactInformationFormsAdapter.setOne(lastSavedData, state.lastSavedContactInformationForms),
    };
  }),
  /**
   * Add/update a contact information form data
   */
  on(addInitialContactInformationFormData, (state, { featureId, contactInformationForm }) => {
    return {
      ...state,
      contactInformationForms: contactInformationFormsAdapter.setOne(contactInformationForm, state.contactInformationForms),
    };
  }),
  /**
   * Update a contact information form phone data
   */
  on(updateContactInformationFormPhoneData, (state, { featureId, phoneData, index, hasPrimaryPhoneForm }) => {
    const existingContactInformationForm = getExistingContactInformationForm(state, featureId);
    const updatedPhoneForms = updatePhoneForms(existingContactInformationForm.phoneForms, phoneData, index, hasPrimaryPhoneForm);
    const updatedContactInformationForm = updateContactInformationForm(existingContactInformationForm, updatedPhoneForms);

    return updateStateWithNewContactInformationForm(state, updatedContactInformationForm);
  }),

  /**
   * Adds a new phone form to the contact information form
   */
  on(addNewContactInformationPhoneNumberForm, (state, { featureId }) => {
    const existingContactInformationForm = getExistingContactInformationForm(state, featureId);
    const newPhoneForm = createNewPhoneForm(existingContactInformationForm.phoneForms.length);
    const updatedPhoneForms = [...existingContactInformationForm.phoneForms, newPhoneForm];
    const updatedContactInformationForm = updateContactInformationForm(existingContactInformationForm, updatedPhoneForms);

    return updateStateWithNewContactInformationForm(state, updatedContactInformationForm);
  }),

  /**
   * Removes a phone form from the contact information form
   */
  on(removeContactInformationPhoneNumberForm, (state, { featureId, index }) => {
    const existingContactInformationForm = getExistingContactInformationForm(state, featureId);
    const updatedPhoneForms = removePhoneForm(existingContactInformationForm.phoneForms, index);
    const updatedContactInformationForm = updateContactInformationForm(existingContactInformationForm, updatedPhoneForms);

    return updateStateWithNewContactInformationForm(state, updatedContactInformationForm);
  }),
  /**
   * Update a contact information form email data
   */
  on(updateContactInformationFormEmailData, (state, { featureId, emailData, index }) => {
    const existingContactInformationForm = state.contactInformationForms.entities[featureId];
    const updatedEmailForms = existingContactInformationForm.emailForms?.map((emailForm, i) => (i === index ? emailData : emailForm));
    const updatedContactInformationForm = {
      ...existingContactInformationForm,
      emailForms: updatedEmailForms,
    };
    return {
      ...state,
      contactInformationForms: contactInformationFormsAdapter.setOne(updatedContactInformationForm, state.contactInformationForms),
    };
  })
);


/**
 * Retrieves the existing contact information form for the given feature ID.
 */
function getExistingContactInformationForm(state: ContactInformationFormsState, featureId: string) {
  return state.contactInformationForms.entities[featureId];
}

/**
 * Updates the phone forms with the new phone data.
 */
function updatePhoneForms(
  phoneForms: PhoneFormData[], phoneData: PhoneFormData, index: number, hasPrimaryPhoneForm: boolean
) {
  return phoneForms.map((phoneForm, i) => {
    if (i === index) {
      const phoneLabel = hasPrimaryPhoneForm && i === 0 ? PRIMARY_PHONE_NUMBER_FORM_LABEL : GUEST_PHONE_NUMBER_FORM_LABEL;
      const required = hasPrimaryPhoneForm && i === 0;
      return { ...phoneData, index: i, phoneLabel, required };
    } else {
      return phoneForm;
    }
  });
}

/**
 * Creates a new phone form.
 */
function createNewPhoneForm(index: number) {
  return { phonePrefix: '', phoneNumber: '', required: false, index };
}

/**
 * Removes a phone form from the list and updates the indices and labels.
 */
function removePhoneForm(
  phoneForms: PhoneFormData[], index: number
) {
  return phoneForms
    .filter((_, i) => i !== index)
    .map((phoneForm, i) => {
      return { ...phoneForm, index: i };
    });
}

/**
 * Updates the contact information form with the new list of phone forms.
 */
function updateContactInformationForm(
  existingForm: ContactInformationForm, updatedPhoneForms: PhoneFormData[]
) {
  return {
    ...existingForm,
    phoneForms: updatedPhoneForms,
  };
}

/**
 * Updates the state with the new contact information form.
 */
function updateStateWithNewContactInformationForm(
  state: ContactInformationFormsState, updatedForm: ContactInformationForm
) {
  return {
    ...state,
    contactInformationForms: contactInformationFormsAdapter.setOne(updatedForm, state.contactInformationForms),
  };
}

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function contactInformationFormsReducer(state = initialContactInformationFormsState, action: Action) {
  return featureReducer(state, action);
}
