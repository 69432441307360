import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, enableProdMode, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OAuthModule } from 'angular-oauth2-oidc';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './features/login/login.module';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { ServicesModule } from './services/services.module';
import { UXModule } from './shared/ux/ux.module';
import { rootMetaReducers, rootReducers } from './state/reducers';

if (environment.production) {
  enableProdMode();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    // RAIN Modules (Note - do NOT include lazy-loaded routed feature modules here)
    AppRoutingModule.forRoot(),
    LoginModule,
    ServicesModule.forRoot(),
    UXModule,
    InlineSVGModule.forRoot(),
    OAuthModule.forRoot(),
    StoreModule.forRoot(rootReducers, {
      metaReducers: rootMetaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'RAIN DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(),
  ],
  providers:
  [
    provideHttpClient(withInterceptorsFromDi()),
    DatePipe,
    TitleCasePipe,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
