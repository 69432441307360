import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth-service/auth.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: false
})
export class AuthComponent implements OnInit, OnDestroy {
  authenticating = true;
  private subscriptions: Subscription[];

  constructor(private authService: AuthService, private router: Router) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.onTokenReceived.subscribe(() => {
        this.router.navigateByUrl('login');
      })
    );
    this.subscriptions.push(
      this.authService.onError.subscribe(() => {
        this.authenticating = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
