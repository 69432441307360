import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageFactoryService } from '../message-factory.service';
import { RootState } from '../../../state/state';
import { Store } from '@ngrx/store';
import {
  addStackedMessage,
  addStackedMessageFailure,
  addStackedMessageSuccess,
  addStackedMessageNoDuplicate,
  addMessageFailure,
  addMessageSuccess,
  addMessage,
  addMultipleJetStreamMessagesNoDuplicate,
  addJetStreamMessageComplete,
  addJetStreamMessage,
  addJetStreamShortMessage,
  addJetStreamShortMessageComplete,
  addGlobalMessage,
  addGlobalMessageFailure,
  addGlobalMessageSuccess,
  addGlobalJetStreamMessage,
  addGlobalJetStreamMessageComplete,
  addMileagePlanMessage,
  addMileagePlanMessageFailure,
  addMileagePlanMessageSuccess,
  addCarekitMessage,
  addCarekitMessageFailure,
  addCarekitMessageSuccess,
  addCouponMessage,
  addCouponMessageFailure,
  addCouponMessageSuccess,
  addInvoluntaryChangeMessage,
  addInvoluntaryChangeMessageFailure,
  addInvoluntaryChangeMessageSuccess,
  addManualAssociationMessage,
  addManualAssociationMessageSuccess,
  addManualAssociationMessageFailure,
  addPaymentMessage,
  addPaymentMessageFailure,
  addPaymentMessageSuccess,
  addTcpMessage,
  addTcpMessageFailure,
  addTcpMessageSuccess,
} from './message.actions';
import { exhaustMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageStatus } from '../../../models/message/message-status';
import { getCarekitLongMessages, getMileagePlanMessages, getMessageStateStatus, getCouponMessages, getIrropMessages, getManualAssociationMessages, getPaymentMessages, getTcpMessages } from './message.selectors';
import { RichMessage } from '../../../models/message/rich-message';

@Injectable()
export class MessageEffects {
  constructor(private actions$: Actions, private messageFactoryService: MessageFactoryService, private store: Store<RootState>) {}

  addGlobalMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGlobalMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addGlobalMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }
        return of(addGlobalMessageSuccess(message));
      })
    )
  );

  addStackedMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addStackedMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addStackedMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }
        return of(addStackedMessageSuccess(message));
      })
    )
  );

  addMileagePlanMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMileagePlanMessage),
      withLatestFrom(this.store.select(getMileagePlanMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addMileagePlanMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addMileagePlanMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addMileagePlanMessageSuccess(message));
      })
    )
  );

  addCarekitMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCarekitMessage),
      withLatestFrom(this.store.select(getCarekitLongMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addCarekitMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addCarekitMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addCarekitMessageSuccess(message));
      })
    )
  );

  addCouponMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCouponMessage),
      withLatestFrom(this.store.select(getCouponMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addCouponMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addCouponMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addCouponMessageSuccess(message));
      })
    )
  );

  addInvoluntaryChangeMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addInvoluntaryChangeMessage),
      withLatestFrom(this.store.select(getIrropMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs, action.messageDetails);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addInvoluntaryChangeMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addInvoluntaryChangeMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addInvoluntaryChangeMessageSuccess(message));
      })
    )
  );

  addManualAssociationMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addManualAssociationMessage),
      withLatestFrom(this.store.select(getManualAssociationMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs, action.messageDetails);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addManualAssociationMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addManualAssociationMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addManualAssociationMessageSuccess(message));
      })
    )
  );

  addPaymentMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPaymentMessage),
      withLatestFrom(this.store.select(getPaymentMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs, action.messageDetails);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addPaymentMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addPaymentMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addPaymentMessageSuccess(message));
      })
    )
  );

  addTcpMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTcpMessage),
      withLatestFrom(this.store.select(getTcpMessages)),
      exhaustMap(([action, messages]) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        const isDuplicateMessage = messages.some(m => m.key === message.key);

        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addTcpMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }

        if (isDuplicateMessage) {
          return of(addTcpMessageFailure(MessageStatus.DUPLICATE));
        }

        return of(addTcpMessageSuccess(message));
      })
    )
  );

  addMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
        }
        return of(addMessageSuccess(message));
      })
    )
  );

  addStackedMessageNoDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addStackedMessageNoDuplicate),
      withLatestFrom(this.store.select(getMessageStateStatus)),
      exhaustMap(([action, messageStatus]) => {
        if (messageStatus !== MessageStatus.DUPLICATE) {
          const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs, action.messageDetails);
          if (!message || (!message.mainText && !message.type && !message.priority)) {
            return of(addStackedMessageFailure(MessageStatus.UNHANDLED_MESSAGE));
          }
          message.segmentIndex = action.messageFilters?.segmentIndex;
          message.passengerId = action.messageFilters?.passengerId;
          return of(addStackedMessageSuccess(message));
        }
        return of(addStackedMessageFailure(MessageStatus.DUPLICATE));
      })
    )
  );

  addMultipleJetStreamMessagesNoDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMultipleJetStreamMessagesNoDuplicate),
      exhaustMap((action) => {
        const messagesToAdd: RichMessage[] = [];
        const uniqueKeys = [...new Set(action.keys)];
        uniqueKeys.forEach((key) => {
          const message = this.messageFactoryService.getRichMessage(key, action.messageArgs);
          if (message) {
            message.passengerId = action.messageFilters?.passengerId;
            messagesToAdd.push(message);
          }
        });
        return of(addJetStreamMessageComplete(messagesToAdd));
      })
    )
  );

  addGlobalJetStreamMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGlobalJetStreamMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addGlobalJetStreamMessageComplete([]));
        }
        return of(addGlobalJetStreamMessageComplete([message]));
      })
    )
  );

  addJetStreamMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJetStreamMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addJetStreamMessageComplete([]));
        }
        return of(addJetStreamMessageComplete([message]));
      })
    )
  );

  addJetStreamShortMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJetStreamShortMessage),
      exhaustMap((action) => {
        const message = this.messageFactoryService.getRichMessage(action.key, action.messageArgs);
        if (!message || (!message.mainText && !message.type && !message.priority)) {
          return of(addJetStreamShortMessageComplete([]));
        }
        return of(addJetStreamShortMessageComplete([message]));
      })
    )
  );
}
